@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-xl: 20px;
  --font-size-11xl: 30px;
  --font-size-31xl: 50px;
  --font-size-21xl: 40px;
  --font-size-base: 16px;

  /* Colors */
  --color-tomato: #dc532b;
  --color-white: #fff;
  --color-black: #000;
  --color-chocolate: #e8872d;

  /* Border radiuses */
  --br-7xs: 6px;
}
